import { TRANSACTION_CODE_MAP } from "constants/insiderTransactionsConstants";
import { CircleIcon } from "lucide-react";
import {
  InsiderTransaction,
  TransactionCode,
} from "types/insiderTransactionsTypes";

export const formatUtils = {
  formatCurrency: (value: number | undefined) => {
    if (value === undefined) return "N/A";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: value >= 1000000 ? "compact" : "standard",
      maximumFractionDigits: value >= 1000000 ? 1 : 2,
    }).format(value);
  },

  formatNumber: (value: number | undefined) => {
    if (value === undefined) return "N/A";
    return new Intl.NumberFormat("en-US", {
      notation: value >= 1000000 ? "compact" : "standard",
      maximumFractionDigits: value >= 1000000 ? 1 : 0,
    }).format(value);
  },

  formatDate: (dateString: string) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(date);
    } catch (e) {
      return dateString;
    }
  },

  getTransactionInfo: (code: TransactionCode) => {
    return (
      TRANSACTION_CODE_MAP[code] || {
        label: code,
        color: "bg-gray-100 text-gray-800",
        icon: <CircleIcon className="h-3.5 w-3.5" />,
        description: "Unknown transaction type",
      }
    );
  },

  getTotalValue: (transaction: InsiderTransaction) => {
    if (!transaction.transactions || transaction.transactions.length === 0) {
      return 0;
    }
    return transaction.transactions.reduce(
      (sum, t) => sum + (t.total_value || 0),
      0,
    );
  },
};
