import { useMemo, useState } from "react";

import LoadingTab from "components/stock-tabs/tabs/common/LoadingTab";
import { Button } from "components/ui/button";
import { Card, CardContent } from "components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Input } from "components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import { useMobileView } from "hooks/useMobileView";
import {
  ArrowUpDown,
  Calendar,
  ChevronDown,
  Filter,
  Info,
  Search,
} from "lucide-react";
import { InsiderTransaction } from "types/insiderTransactionsTypes";
import { fetchInsiderTransactions } from "util/Http";

import { useQuery } from "@tanstack/react-query";

import InsiderTransactionsEmptyState from "./InsiderTransactionsEmptyState";
import InsiderTransactionsErrorState from "./InsiderTransactionsErrorState";
import InsiderTransactionsInsiderInfo from "./InsiderTransactionsInsiderInfo";
import InsiderTransactionsTransactionBadge from "./InsiderTransactionsTransactionBadge";
import InsiderTransactionsTransactionFullDetails from "./InsiderTransactionsTransactionFullDetails";
import { formatUtils } from "./InsiderTransactionsUtils";

interface InsiderTransactionsProps {
  stock: string;
}

export default function InsiderTransactions({
  stock,
}: InsiderTransactionsProps) {
  const isMobile = useMobileView();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);
  const [activeView, setActiveView] = useState<"all" | "buys" | "sells">("all");
  const [selectedTransaction, setSelectedTransaction] =
    useState<InsiderTransaction | null>(null);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);

  const { data, isLoading, error } = useQuery<InsiderTransaction[]>({
    queryKey: ["InsiderTransactions", stock],
    queryFn: () => fetchInsiderTransactions(stock),
  });

  const filteredTransactions = useMemo(() => {
    if (!data) return [];

    return data
      .filter((transaction) => {
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          const insiderMatch = transaction.insiders.some(
            (insider) =>
              insider.name.toLowerCase().includes(searchLower) ||
              insider.title.toLowerCase().includes(searchLower),
          );

          if (insiderMatch) return true;
          if (transaction.form_type.toLowerCase().includes(searchLower))
            return true;

          return false;
        }
        return true;
      })
      .filter((transaction) => {
        if (filterType) {
          return transaction.transactions.some(
            (t) => t.security_type === filterType,
          );
        }
        return true;
      })
      .filter((transaction) => {
        if (activeView === "buys") {
          return transaction.transactions.some((t) => t.code === "P");
        } else if (activeView === "sells") {
          return transaction.transactions.some((t) => t.code === "S");
        }
        return true;
      });
  }, [data, searchTerm, filterType, activeView]);

  const sortedTransactions = useMemo(() => {
    if (!filteredTransactions.length) return [];

    return [...filteredTransactions].sort((a, b) => {
      if (!sortConfig) {
        return (
          new Date(b.period_of_report).getTime() -
          new Date(a.period_of_report).getTime()
        );
      }

      if (sortConfig.key === "date") {
        const result =
          new Date(b.period_of_report).getTime() -
          new Date(a.period_of_report).getTime();
        return sortConfig.direction === "asc" ? -result : result;
      }

      if (sortConfig.key === "insider") {
        const insiderA = a.insiders[0]?.name || "";
        const insiderB = b.insiders[0]?.name || "";
        const result = insiderA.localeCompare(insiderB);
        return sortConfig.direction === "asc" ? result : -result;
      }

      if (sortConfig.key === "value") {
        const valueA = formatUtils.getTotalValue(a);
        const valueB = formatUtils.getTotalValue(b);
        const result = valueA - valueB;
        return sortConfig.direction === "asc" ? result : -result;
      }

      return 0;
    });
  }, [filteredTransactions, sortConfig]);

  const requestSort = (key: string) => {
    let direction: "asc" | "desc" = "desc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "desc"
    ) {
      direction = "asc";
    }
    setSortConfig({ key, direction });
  };

  const handleViewDetails = (transaction: InsiderTransaction) => {
    setSelectedTransaction(transaction);
    setIsDetailDialogOpen(true);
  };

  if (isLoading) {
    return <LoadingTab text="Loading Insider Transactions" />;
  }

  if (error) {
    return <InsiderTransactionsErrorState stock={stock} />;
  }

  if (!data || data.length === 0) {
    return <InsiderTransactionsEmptyState stock={stock} />;
  }

  return (
    <Card className="w-full">
      <CardContent className="p-4 md:p-6">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between mb-4">
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search by name or title..."
                className="pl-8 w-full sm:w-[200px] md:w-[250px]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="gap-1">
                  <Filter className="h-4 w-4" />
                  <span className="hidden sm:inline">Filter</span>
                  <ChevronDown className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[200px]">
                <DropdownMenuLabel>Security Type</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    onClick={() => setFilterType(null)}
                    className={!filterType ? "bg-accent" : ""}
                  >
                    All Types
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => setFilterType("Common Stock")}
                    className={filterType === "Common Stock" ? "bg-accent" : ""}
                  >
                    Common Stock
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => setFilterType("Stock Options")}
                    className={
                      filterType === "Stock Options" ? "bg-accent" : ""
                    }
                  >
                    Stock Options
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => setFilterType("Restricted Stock")}
                    className={
                      filterType === "Restricted Stock" ? "bg-accent" : ""
                    }
                  >
                    Restricted Stock
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => setFilterType("Other")}
                    className={filterType === "Other" ? "bg-accent" : ""}
                  >
                    Other
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <Tabs
            value={activeView}
            onValueChange={(value) =>
              setActiveView(value as "all" | "buys" | "sells")
            }
            className="w-full md:w-auto"
          >
            <TabsList className="grid w-full grid-cols-3 max-w-md">
              <TabsTrigger value="all">All</TabsTrigger>
              <TabsTrigger value="buys" className="text-green-600">
                Buys
              </TabsTrigger>
              <TabsTrigger value="sells" className="text-red-600">
                Sells
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <div className="rounded-md border overflow-hidden">
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-center w-[180px] md:w-[250px]">
                    Insider
                  </TableHead>
                  {!isMobile && (
                    <>
                      <TableHead>
                        <Button
                          variant="ghost"
                          onClick={() => requestSort("date")}
                          className="flex text-center items-center gap-1 font-semibold"
                        >
                          Date
                          <ArrowUpDown className="h-3.5 w-3.5" />
                        </Button>
                      </TableHead>
                      <TableHead className="text-center min-w-[120px]">
                        Transaction
                      </TableHead>
                      <TableHead>
                        <Button
                          variant="ghost"
                          onClick={() => requestSort("value")}
                          className="flextext-center  items-center gap-1 font-semibold"
                        >
                          Value
                          <ArrowUpDown className="h-3.5 w-3.5" />
                        </Button>
                      </TableHead>
                    </>
                  )}
                  <TableHead className="text-center">Details</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedTransactions.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={isMobile ? 2 : 5}
                      className="h-24 text-center"
                    >
                      No transactions match your filters.
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedTransactions.map((transaction, index) => (
                    <TableRow key={`${transaction.cik}-${index}`}>
                      {/* Insider Column */}
                      <TableCell className="font-medium">
                        <InsiderTransactionsInsiderInfo
                          insiders={transaction.insiders}
                        />
                        {isMobile && (
                          <div className="text-xs text-muted-foreground mt-1 ml-5">
                            {formatUtils.formatDate(
                              transaction.period_of_report,
                            )}
                          </div>
                        )}
                      </TableCell>

                      {/* Desktop Only Columns */}
                      {!isMobile && (
                        <>
                          {/* Date Column */}
                          <TableCell>
                            <div className="flex items-center gap-1.5">
                              <Calendar className="h-3.5 w-3.5 text-muted-foreground" />
                              {formatUtils.formatDate(
                                transaction.period_of_report,
                              )}
                            </div>
                          </TableCell>

                          {/* Transaction Type Column */}
                          <TableCell>
                            {transaction.transactions &&
                              transaction.transactions.map((t, i) => (
                                <InsiderTransactionsTransactionBadge
                                  key={i}
                                  transaction={t}
                                />
                              ))}
                          </TableCell>

                          {/* Value Column */}
                          <TableCell>
                            <div className="font-medium text-center">
                              {formatUtils.formatCurrency(
                                formatUtils.getTotalValue(transaction),
                              )}
                            </div>
                          </TableCell>
                        </>
                      )}

                      {/* Details Column */}
                      <TableCell className="text-center">
                        <Button
                          variant="ghost"
                          size="sm"
                          className={isMobile ? "h-8 px-2" : "h-8 w-8 p-0"}
                          onClick={() => handleViewDetails(transaction)}
                        >
                          <Info className="h-4 w-4" />
                          {isMobile && <span className="ml-1.5">Details</span>}
                          <span className="sr-only">View details</span>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </CardContent>

      {/* Transaction Detail Dialog */}
      {selectedTransaction && (
        <InsiderTransactionsTransactionFullDetails
          transaction={selectedTransaction}
          open={isDetailDialogOpen}
          onOpenChange={setIsDetailDialogOpen}
        />
      )}
    </Card>
  );
}
