export type Exchange =
  | "NASDAQ"
  | "NYSE"
  | "OTC"
  | "UNKNOWN";

export type StockTier =
  | "OTCQX International"
  | "OTCQX"
  | "OTCQB"
  | "Pink Current Information"
  | "Pink Limited Information"
  | "Expert Market";

export const TierDisplay: Record<StockTier, string> = {
  "OTCQX International": "OTCQX",
  OTCQX: "OTCQX",
  OTCQB: "OTCQB",
  "Pink Current Information": "PINK",
  "Pink Limited Information": "PINK LMT",
  "Expert Market": "EXPERT",
};

export interface Stock {
  symbol: string;
  name: string;
  exchange: string;
}
