import { mockedLevel2 } from "assets/mockData";
import SignInCard from "components/SignInCard";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { formatNumberInput } from "util/formatNumberInput";

export default function LockedLevel2() {
  return (
    <div className="relative w-full h-[500px]">
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-full filter blur-sm opacity-50">
          <div className="grid grid-cols-2 gap-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead colSpan={3} className="text-center">
                    Bid
                  </TableHead>
                </TableRow>
                <TableRow>
                  <TableHead>MM</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Size</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {mockedLevel2.bid?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.marketMaker}</TableCell>
                    <TableCell>{item.price}</TableCell>
                    <TableCell>{formatNumberInput(item.size)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead colSpan={3} className="text-center">
                    Ask
                  </TableHead>
                </TableRow>
                <TableRow>
                  <TableHead>MM</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Size</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {mockedLevel2.ask?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.marketMaker}</TableCell>
                    <TableCell>{item.price}</TableCell>
                    <TableCell>{formatNumberInput(item.size)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>

      <div className="absolute inset-0 flex items-center justify-center">
        <SignInCard description="View real-time Level 2 market depth" />
      </div>
    </div>
  );
}
