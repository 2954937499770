import { useState } from "react";

import axios from "axios";
import AuthRequiredCard from "components/AuthRequiredCard";
import Level2MarketHoursCard from "components/Level2MarketHoursCard";
import RateLimitCard from "components/ui/RateLimitCard";
import { Button } from "components/ui/button";
import { Card, CardContent } from "components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import useIsMarketActive from "hooks/useIsMarketActive";
import { useLevel2Data } from "hooks/useStockData";
import { AlertCircle, Loader2, RefreshCw } from "lucide-react";
import { formatNumberInput } from "util/formatNumberInput";

import LoadingTab from "./common/LoadingTab";

interface Level2TableProps {
  stock: string;
}

const NoDataCard = ({ stock }: { stock: string }) => (
  <Card className="mx-auto max-w-lg">
    <CardContent className="flex flex-col items-center justify-center gap-4 py-12">
      <AlertCircle className="h-12 w-12 text-muted-foreground/50" />
      <div className="text-center">
        <h3 className="text-lg font-semibold">No Level 2 Data Available</h3>
        <p className="mt-2 text-sm text-muted-foreground">
          Level 2 market data is currently unavailable for {stock.toUpperCase()}
          . This could be due to low trading volume or market conditions.
        </p>
      </div>
    </CardContent>
  </Card>
);

export default function Level2Table({ stock }: Level2TableProps) {
  const marketActive = useIsMarketActive();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { data, isLoading, isError, error, refetch } = useLevel2Data(
    stock,
    marketActive,
  );

  if (!marketActive) {
    return <Level2MarketHoursCard />;
  }

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await refetch();
    setIsRefreshing(false);
  };

  if (isLoading) {
    return <LoadingTab text="Loading Level 2 Data" />;
  }

  if (isError) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 429) {
        const headers = error.response.headers;
        const rateLimit = parseInt(headers["x-ratelimit-limit"] || "0", 10);

        return <RateLimitCard limitCount={rateLimit} endpoint="Level 2 data" />;
      }

      if (error.response?.status === 403) {
        return (
          <AuthRequiredCard actionMessage="Please log in to view level 2 data." />
        );
      }
    }

    return <NoDataCard stock={stock} />;
  }

  if (!data?.bid?.length && !data?.ask?.length) {
    return <NoDataCard stock={stock} />;
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead colSpan={3} className="text-center">
              Bid
            </TableHead>
          </TableRow>
          <TableRow>
            <TableHead>MM</TableHead>
            <TableHead>Price</TableHead>
            <TableHead>Size</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.bid?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.marketMaker.toUpperCase()}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{formatNumberInput(item.size)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead colSpan={3} className="text-center relative">
              <div className="flex items-center justify-between">
                <div className="w-6" />
                <span>Ask</span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={handleRefresh}
                  disabled={isRefreshing}
                  className="h-6 w-6 pb-1 pr-1"
                >
                  <div className="h-3 w-3">
                    {isRefreshing ? (
                      <Loader2 className="h-3 w-3 animate-spin" />
                    ) : (
                      <RefreshCw className="h-3 w-3" />
                    )}
                  </div>
                </Button>
              </div>
            </TableHead>
          </TableRow>
          <TableRow>
            <TableHead>MM</TableHead>
            <TableHead>Price</TableHead>
            <TableHead>Size</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.ask?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.marketMaker.toUpperCase()}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{formatNumberInput(item.size)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
