import { useState } from "react";

import { Badge } from "components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { ChevronDown } from "lucide-react";
import { Transaction } from "types/insiderTransactionsTypes";

import { formatUtils } from "./InsiderTransactionsUtils";

interface TransactionBadgeProps {
  transaction: Transaction;
}

export default function TransactionBadge({
  transaction,
}: TransactionBadgeProps) {
  const [isOpen, setIsOpen] = useState(false);
  const info = formatUtils.getTransactionInfo(transaction.code);

  return (
    <div className="mb-1.5 last:mb-0">
      <Badge
        onClick={() => setIsOpen(true)}
        className={`${info.color} gap-1 cursor-pointer whitespace-nowrap inline-flex items-center pr-1.5`}
      >
        {info.icon}
        <span className="truncate">{info.label}</span>
        <ChevronDown className="h-3 w-3 ml-0.5 opacity-70" />
      </Badge>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              {info.icon}
              {info.label} Transaction
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-3 py-2">
            <p className="text-sm text-muted-foreground">{info.description}</p>

            <div className="grid grid-cols-2 gap-x-3 gap-y-2 text-sm bg-muted/40 p-3 rounded-md">
              <span className="text-muted-foreground">Security:</span>
              <span className="font-medium">{transaction.security_type}</span>

              <span className="text-muted-foreground">Shares:</span>
              <span className="font-medium">
                {formatUtils.formatNumber(transaction.shares)}
                {transaction.ownership_change_percent && (
                  <span className="text-xs ml-1.5">
                    ({transaction.ownership_change_percent > 0 ? "+" : ""}
                    {transaction.ownership_change_percent.toFixed(1)}%)
                  </span>
                )}
              </span>

              <span className="text-muted-foreground">Price:</span>
              <span className="font-medium">
                {transaction.price_per_share
                  ? formatUtils.formatCurrency(transaction.price_per_share)
                  : "N/A"}
              </span>

              {transaction.total_value !== undefined && (
                <>
                  <span className="text-muted-foreground">Value:</span>
                  <span className="font-medium">
                    {formatUtils.formatCurrency(transaction.total_value)}
                  </span>
                </>
              )}
            </div>

            {transaction.is_10b5_1 && (
              <div className="pt-2 border-t border-border">
                <div className="flex items-center gap-1.5 mb-1">
                  <Badge
                    variant="outline"
                    className="text-[10px] px-1 py-0 h-4"
                  >
                    10b5-1
                  </Badge>
                  <span className="text-sm font-medium">
                    Planned Transaction
                  </span>
                </div>
                <p className="text-xs text-muted-foreground">
                  This is a pre-planned transaction set up by the insider under
                  SEC Rule 10b5-1, which allows insiders to establish trading
                  plans when they are not in possession of material non-public
                  information.
                </p>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
