import { mockedDilutionData } from "assets/mockData";
import SignInCard from "components/SignInCard";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const lineConfig = [
  {
    id: "authorizedShares",
    label: "Authorized Shares",
    color: "hsl(var(--chart-4))",
  },
  {
    id: "unrestrictedShares",
    label: "Unrestricted Shares",
    color: "hsl(var(--chart-1))",
  },
  {
    id: "restrictedShares",
    label: "Restricted Shares",
    color: "hsl(var(--chart-2))",
  },
  { id: "dtcShares", label: "DTC Shares", color: "hsl(var(--chart-3))" },
];

export default function LockedDilutionChart() {
  const formatNumber = (value: number) => {
    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(1)}B`;
    }
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    }
    return value.toString();
  };

  return (
    <div className="relative w-full h-[500px]">
      <div className="absolute inset-0 filter blur-sm opacity-50">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={mockedDilutionData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(date) => {
                const d = new Date(date);
                return `${d.getMonth() + 1}/${d.getFullYear().toString().slice(2)}`;
              }}
              tick={{ fontSize: 12 }}
            />
            <YAxis tickFormatter={formatNumber} tick={{ fontSize: 12 }} />
            {lineConfig.map((config) => (
              <Line
                key={config.id}
                type="monotone"
                dataKey={config.id}
                stroke={config.color}
                strokeWidth={2}
                dot={false}
                connectNulls
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="absolute inset-0 flex items-center justify-center">
        <SignInCard description="Track share structure changes and analyze dilution patterns" />
      </div>
    </div>
  );
}
