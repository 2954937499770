import { useMemo, useState } from "react";

import axios from "axios";
import AuthRequiredCard from "components/AuthRequiredCard";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "components/ui/chart";
import { Checkbox } from "components/ui/checkbox";
import DilutionLoadingAnimation from "components/ui/dilution-loading-animation";
import { useDilutionData } from "hooks/useStockData";
import { AlertCircle } from "lucide-react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { VisibleSeries } from "types/dilution";
import getDilutionDataset from "util/getDilutionDataset";

const lineConfig = [
  {
    id: "authorizedShares",
    label: "Authorized Shares",
    color: "hsl(var(--chart-4))",
  },
  {
    id: "unrestrictedShares",
    label: "Unrestricted Shares",
    color: "hsl(var(--chart-1))",
  },
  {
    id: "restrictedShares",
    label: "Restricted Shares",
    color: "hsl(var(--chart-2))",
  },
  { id: "dtcShares", label: "DTC Shares", color: "hsl(var(--chart-3))" },
];

interface DilutionDataProps {
  stock: string;
}

const formatNumber = (value: number) => {
  if (value >= 1000000000) {
    return `${parseFloat((value / 1000000000).toFixed(1))}B`;
  }
  if (value >= 1000000) {
    return `${parseFloat((value / 1000000).toFixed(1))}M`;
  }
  if (value >= 1000) {
    return `${parseFloat((value / 1000).toFixed(1))}K`;
  }
  return value.toString();
};

const formatDate = (date: Date) => {
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
};

export default function DilutionChart({ stock }: DilutionDataProps) {
  const [visibleSeries, setVisibleSeries] = useState<VisibleSeries>({
    unrestrictedShares: true,
    restrictedShares: true,
    dtcShares: true,
    authorizedShares: true,
  });

  const { data, isLoading, isError, error } = useDilutionData(stock);

  const handleToggle = (seriesId: keyof VisibleSeries) => {
    setVisibleSeries((prev) => ({
      ...prev,
      [seriesId]: !prev[seriesId],
    }));
  };

  const { chartData, activeSeriesData } = useMemo(() => {
    if (!data) return { chartData: [], activeSeriesData: [] };

    const { chartDates, seriesData } = getDilutionDataset(data);
    const activeSeriesData = seriesData.filter(
      (series) => visibleSeries[series.id as keyof VisibleSeries],
    );

    const allDataPoints = chartDates.map((date, index) => {
      const dataPoint: any = {
        name: formatDate(new Date(date.dates)),
        date: date.dates,
      };
      seriesData.forEach((series) => {
        if (visibleSeries[series.id as keyof VisibleSeries]) {
          dataPoint[series.id] = series.data[index];
        }
      });
      return dataPoint;
    });

    const firstValidIndex = allDataPoints.findIndex((point) =>
      Object.keys(visibleSeries).some(
        (key) =>
          visibleSeries[key as keyof VisibleSeries] &&
          point[key] !== undefined &&
          point[key] !== null,
      ),
    );

    const filteredData =
      firstValidIndex >= 0
        ? allDataPoints.slice(firstValidIndex)
        : allDataPoints;

    return {
      chartData: filteredData,
      activeSeriesData,
    };
  }, [data, visibleSeries]);

  const chartConfig = useMemo(() => {
    const config: Record<
      string,
      {
        label: string;
        color: string;
        valueFormatter: (value: number) => string;
      }
    > = {};
    lineConfig.forEach((line) => {
      config[line.id] = {
        label: line.label,
        color: line.color,
        valueFormatter: formatNumber,
      };
    });
    return config;
  }, []);

  if (isLoading) {
    return (
      <Card className="w-full">
        <CardContent className="flex justify-center items-center h-80">
          <DilutionLoadingAnimation />
        </CardContent>
      </Card>
    );
  }

  if (isError || !data) {
    if (axios.isAxiosError(error) && error.response?.status === 403) {
      return (
        <AuthRequiredCard actionMessage="Please upgrade your account to view dilution data." />
      );
    }
    return (
      <Card className="w-full">
        <CardContent className="flex flex-col justify-center items-center h-80 p-6">
          <div className="flex flex-col items-center text-center space-y-6">
            <div className="text-muted-foreground/50">
              <AlertCircle className="h-12 w-12" />
            </div>

            <div className="space-y-3">
              <h3 className="text-lg font-medium">
                Unable to Load Dilution Chart
              </h3>
              <p className="text-sm text-muted-foreground max-w-sm">
                We encountered an issue while fetching the dilution data. Please
                try again later.
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  // const hasNoData =
  //   data &&
  //   [
  //     "authorizedShares",
  //     "restrictedShares",
  //     "unrestrictedShares",
  //     "dtcShares",
  //   ].every((key) => data[key as keyof DilutionData].values.length === 0);

  // if (hasNoData) {
  //   return (
  //     <Card className="w-full">
  //       <CardContent className="flex flex-col justify-center items-center h-80 space-y-4">
  //         <div className="text-muted-foreground/80">
  //           <LineChartIcon className="h-12 w-12" />
  //         </div>
  //         <div className="text-center space-y-2">
  //           <h3 className="font-medium text-lg">
  //             No Share Structure Data Available
  //           </h3>
  //           <p className="text-muted-foreground text-sm max-w-[300px]">
  //             We currently don't have any share structure data for this stock.
  //             This information may be added in future updates.
  //           </p>
  //         </div>
  //       </CardContent>
  //     </Card>
  //   );
  // }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-lg sm:text-xl">
          Dilution History Chart
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2 sm:gap-4 mb-4">
          {lineConfig.map((line) => (
            <div key={line.id} className="flex items-center space-x-2">
              <Checkbox
                id={line.id}
                checked={visibleSeries[line.id as keyof VisibleSeries]}
                onCheckedChange={() =>
                  handleToggle(line.id as keyof VisibleSeries)
                }
              />
              <label
                htmlFor={line.id}
                className="text-xs sm:text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {line.label}
              </label>
            </div>
          ))}
        </div>

        <ChartContainer
          config={chartConfig}
          className="lg:h-[500px] w-full mt-4"
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ top: 5, right: 5, left: 10, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(date) => {
                  const d = new Date(date);
                  return `${d.getMonth() + 1}/${d.getFullYear().toString().slice(2)}`;
                }}
                interval="preserveStartEnd"
              />
              <YAxis width={35} tickFormatter={formatNumber} />
              <ChartTooltip content={<ChartTooltipContent />} />
              {activeSeriesData.map((series) => {
                const config = lineConfig.find((l) => l.id === series.id);
                if (!config) return null;
                return (
                  <Line
                    key={series.id}
                    type="monotone"
                    dataKey={series.id}
                    name={config.label}
                    stroke={`var(--color-${series.id})`}
                    strokeWidth={2}
                    dot={false}
                    connectNulls
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
