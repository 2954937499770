import { useEffect } from "react";
import { Box, Typography, Container, Grid, Chip } from "@mui/material";
import { Check, X } from "lucide-react";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../components/ui/card";
import { logEvent } from "../util/Amplitude";
import { AmplitudeEventsEnum } from "../types/amplitudeEvents";
import { motion } from "framer-motion";

interface PricingTier {
  name: string;
  price: string;
  description: string;
  features: Array<{
    name: string;
    included: boolean;
  }>;
  highlighted?: boolean;
  buttonText: string;
}

const pricingTiers: PricingTier[] = [
  {
    name: "Free",
    price: "$0",
    description: "Basic tools for casual investors",
    features: [
      { name: "Real-time Level 2 market data view", included: true },
      { name: "Stock dilution and share structure insights", included: true },
      { name: "Insider trading activity tracking", included: true },
      { name: "Live SEC filings feed", included: true },
      { name: "Real-time stock news", included: true },
      {
        name: "Real-time alerts on insider trading and SEC filings",
        included: false,
      },
      {
        name: "Instant notifications for volume spikes and unusual activity",
        included: false,
      },
      { name: "AI-powered stock scoring engine", included: false },
      { name: "AI-driven SEC filing summaries and analysis", included: false },
      { name: "Custom indicator-based alert system", included: false },
    ],
    highlighted: true,
    buttonText: "Current Plan",
  },
  {
    name: "Pro",
    price: "$XX.XX",
    description: "Next-level tools and intelligent alerts for serious traders",
    features: [
      { name: "Everything in the basic plan", included: true },
      {
        name: "Real-time alerts on insider trading and SEC filings",
        included: true,
      },
      {
        name: "Instant notifications for volume spikes and unusual activity",
        included: true,
      },
      { name: "AI-powered stock scoring engine", included: true },
      { name: "AI-driven SEC filing summaries and analysis", included: true },
      { name: "Custom indicator-based alert system", included: true },
    ],

    buttonText: "Coming Soon",
  },
];

const PricingPage: React.FC = () => {
  useEffect(() => {
    logEvent(AmplitudeEventsEnum.PAGE_VIEW, { page: "Pricing" });
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ mb: 6, textAlign: "center" }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Choose Your Plan
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
            Select the perfect plan for your investment needs
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
            <Chip
              label="Save 20% with annual billing"
              color="primary"
              sx={{
                fontSize: "0.9rem",
                py: 1,
                background: "linear-gradient(90deg, #3a7bd5, #00d2ff)",
                "& .MuiChip-label": { px: 2 },
              }}
            />
          </Box>
        </Box>
      </motion.div>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid gap-6 md:grid-cols-2"
      >
        {pricingTiers.map((tier, index) => (
          <motion.div key={tier.name} variants={itemVariants}>
            <Card
              className={`h-full flex flex-col ${tier.highlighted ? "border-primary shadow-lg relative overflow-visible" : ""}`}
            >
              {tier.highlighted && (
                <div className="absolute -top-4 left-0 right-0 flex justify-center">
                  <span className="bg-primary text-primary-foreground text-sm font-medium py-1 px-3 rounded-full">
                    Most Popular
                  </span>
                </div>
              )}
              <CardHeader>
                <div className="text-2xl font-bold">{tier.name}</div>
                <div className="flex items-baseline mt-2">
                  <span className="text-4xl font-extrabold tracking-tight">
                    {tier.price}
                  </span>
                  <span className="ml-1 text-sm font-medium text-muted-foreground">
                    /month
                  </span>
                </div>
                <p className="text-sm text-muted-foreground mt-2">
                  {tier.description}
                </p>
              </CardHeader>
              <CardContent className="flex-grow">
                <ul className="space-y-3">
                  {tier.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      {feature.included ? (
                        <Check className="mr-2 h-4 w-4 text-green-500" />
                      ) : (
                        <X className="mr-2 h-4 w-4 text-muted-foreground" />
                      )}
                      <span
                        className={
                          feature.included ? "" : "text-muted-foreground"
                        }
                      >
                        {feature.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <Button
                  className={`w-full ${tier.highlighted ? "bg-primary hover:bg-primary/90" : ""}`}
                  variant={tier.highlighted ? "default" : "outline"}
                >
                  {tier.buttonText}
                </Button>
              </CardFooter>
            </Card>
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
      >
        <Card className="mt-12 bg-muted/50">
          <CardContent className="p-6">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={8}>
                <Typography variant="h5" component="h3" gutterBottom>
                  Need a custom solution?
                </Typography>
                <Typography variant="body1">
                  Whether you're an individual trader, a financial institution,
                  or a hedge fund — if you need something custom, we can build
                  it. Our team specializes in developing tailored trading tools
                  and data solutions. Just reach out and tell us what you need.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.5 }}
      >
        <Box sx={{ mt: 10, textAlign: "center" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Frequently Asked Questions
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Have questions about our pricing?{" "}
            <a href="/faq" className="text-primary hover:underline">
              Visit our FAQ.
            </a>
          </Typography>
        </Box>
      </motion.div>
    </Container>
  );
};

export default PricingPage;
