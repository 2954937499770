import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";

import { fetchStock } from "../util/Http";
import { Stock } from "../types/stock";
import StockCard from "../components/StockCard";
import LoadingOverlay from "../components/ui/LoadingOverlay";
import { logEvent } from "../util/Amplitude";
import { AmplitudeEventsEnum } from "../types/amplitudeEvents";
import StockTabs from "../components/stock-tabs/StockTabs";
import { Box } from "@mui/material";
import StockNotFound from "../components/StockNotFound";
import { useParams } from "react-router";

const StockPage: React.FC = () => {
  const { stock } = useParams<{ stock: string }>();

  useEffect(() => {
    logEvent(AmplitudeEventsEnum.PAGE_VIEW, { page: `stock page: ${stock}` });
  }, [stock]);

  const { data, isLoading, isError } = useQuery<Stock>({
    queryKey: [stock],
    queryFn: () => fetchStock(stock as string),
  });

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (isError) {
    return (
      <Box
        sx={{
          mx: 2,
          mt: 8,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StockNotFound />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        mx: 2,
        mt: 2,
        width: { md: "800px", lg: "1000px" },
        justifySelf: { md: "center" },
      }}
    >
      <StockCard key={data!.symbol} stock={data!} />
      <StockTabs stockName={stock || ""} />
    </Box>
  );
};

export default StockPage;
