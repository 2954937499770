import { useEffect } from "react";

import HomePageBar from "../components/layout/HomePageBar";
import Search from "../components/ui/Search";
import { logEvent } from "../util/Amplitude";
import { AmplitudeEventsEnum } from "../types/amplitudeEvents";
import { Box } from "@mui/material";

const HomePage: React.FC = () => {
  useEffect(() => {
    logEvent(AmplitudeEventsEnum.PAGE_VIEW, { page: "Home" });
  }, []);

  return (
    <Box>
      <HomePageBar />
      <Search />
    </Box>
  );
};

export default HomePage;
