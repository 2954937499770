import { useEffect, useRef, memo, useCallback } from "react";
import { useTheme } from "../ThemeProvider";

interface StockSymbol {
  stock: string;
}

const TradingViewPriceWidget = ({ stock }: StockSymbol) => {
  const { theme } = useTheme();
  const container = useRef<HTMLDivElement>(null);

  const getTheme = useCallback(() => {
    if (theme === "system") {
      return window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    }
    return theme;
  }, [theme]);

  useEffect(() => {
    if (
      container.current &&
      !document.getElementById("tradingview-widget-script")
    ) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
      script.id = "tradingview-widget-script"; // Set an ID to check for duplicates
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [[`${stock}|1Y`]],
        chartOnly: true,
        width: "100%",
        height: "100%",
        locale: "en",
        colorTheme: getTheme(),
        autosize: false,
        showVolume: false,
        showMA: false,
        hideDateRanges: false,
        hideMarketStatus: false,
        hideSymbolLogo: false,
        scalePosition: "right",
        scaleMode: "Normal",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
        fontSize: "10",
        noTimeScale: false,
        valuesTracking: "1",
        changeMode: "price-and-percent",
        chartType: "area",
        maLineColor: "#2962FF",
        maLineWidth: 1,
        maLength: 9,
        headerFontSize: "medium",
        lineWidth: 2,
        lineType: 0,
        dateRanges: ["1d|1", "1m|30", "3m|60", "12m|1D", "60m|1W", "all|1M"],
      });

      setTimeout(() => {
        if (
          container.current &&
          !document.getElementById("tradingview-widget-script")
        ) {
          container.current.appendChild(script);
        }
      }, 100);
    }

    return () => {
      const scriptElement = document.getElementById(
        "tradingview-widget-script"
      );
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, [theme, getTheme, stock]);

  return <div className="tradingview-widget-container" ref={container}></div>;
};

export default memo(TradingViewPriceWidget);
