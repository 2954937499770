// NOTE: This is a full, updated FAQ page with relevant questions for ASE.
"use client";

import type React from "react";

import { useEffect, useState } from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { Card, CardContent, CardHeader } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "../components/ui/tabs";
import { logEvent } from "../util/Amplitude";
import { AmplitudeEventsEnum } from "../types/amplitudeEvents";
import { Search } from "lucide-react";

interface FAQItem {
  question: string;
  answer: string;
  category: "general" | "features" | "troubleshooting";
}

const faqItems: FAQItem[] = [
  {
    question: "What is All Seeing Eye?",
    answer:
      "All Seeing Eye (ASE) is a powerful web-based platform for stock market research, offering real-time Level 2 data, SEC tracking, insider trading activity, dilution tracking, and up-to-date news across OTC, NASDAQ, and NYSE markets.",
    category: "general",
  },
  {
    question: "Is All Seeing Eye free to use?",
    answer:
      "We offer a free tier with real-time access to our core data dashboard. A Pro version with AI insights, alerts, and more advanced features is coming soon.",
    category: "general",
  },
  {
    question: "Do I get real-time market data?",
    answer:
      "Yes! All Seeing Eye provides real-time Level 2 data without delays, helping you track price action and order flow live as it happens.",
    category: "features",
  },
  {
    question: "Does ASE support all markets or just OTC?",
    answer:
      "ASE originally focused on OTC stocks, but we now fully support NASDAQ and NYSE as well — with the same speed and depth of insight.",
    category: "general",
  },
  {
    question: "What is the AI Score feature?",
    answer:
      "Our upcoming Pro plan includes AI-generated scores for each stock based on aggregated SEC data, insider behavior, volume trends, and more.",
    category: "features",
  },
  {
    question: "Can I set alerts for news or indicators?",
    answer:
      "Currently, ASE is a dashboard-only experience. Alerts and push notifications will be part of the upcoming Pro version.",
    category: "features",
  },
  {
    question: "Why isn’t the chart or data loading?",
    answer:
      "Try refreshing the page or checking your internet connection. If issues persist, try another browser or contact support.",
    category: "troubleshooting",
  },
  {
    question: "How often is the data updated?",
    answer:
      "Our market data, SEC filings, and insider trading info update in real time — no manual refresh needed.",
    category: "features",
  },
  {
    question: "Do I need to install anything?",
    answer:
      "No. ASE is 100% web-based. Just visit the site and start exploring — no installation or download required.",
    category: "general",
  },
  {
    question: "Level 2 data isn't loading or looks off. What can I do?",
    answer:
      "Occasionally, issues may arise with our external data provider. Try refreshing the page. If the problem continues, reach out to our support team.",
    category: "troubleshooting",
  },
  {
    question: "Why is dilution data missing for some companies?",
    answer:
      "While we aim to cover as many tickers as possible, some companies may not yet have dilution data available as our system continues to expand its data coverage. We're actively working to improve this every day.",
    category: "troubleshooting",
  },
  {
    question: "What if a stock is missing SEC filings or news updates?",
    answer:
      "In rare cases, certain SEC or news entries may be temporarily unavailable. Please contact our support team so we can investigate and ensure coverage is up to date.",
    category: "troubleshooting",
  },
];

const FAQSection = ({
  title,
  faqs,
  prefix,
}: {
  title: string;
  faqs: FAQItem[];
  prefix: string;
}) => (
  <div className="mb-8">
    <Card className="shadow-sm overflow-hidden">
      <div className="h-1 bg-primary/40"></div>
      <CardHeader className="bg-slate-50 dark:bg-slate-800/50">
        <Typography variant="h5" component="h2" className="font-bold">
          {title}
        </Typography>
      </CardHeader>
      <CardContent>
        <Accordion type="single" collapsible className="w-full">
          {faqs.map((faq, index) => (
            <AccordionItem
              key={index}
              value={`${prefix}-${index}`}
              className="border-b border-slate-200 dark:border-slate-700"
            >
              <AccordionTrigger className="hover:bg-slate-50 dark:hover:bg-slate-800/30 px-4 py-4 rounded-md">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className="px-4 pb-4 pt-2">
                <Typography>{faq.answer}</Typography>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
    </Card>
  </div>
);

const FAQPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("all");

  useEffect(() => {
    logEvent(AmplitudeEventsEnum.PAGE_VIEW, { page: "FAQ" });
  }, []);

  const filteredFAQs = faqItems.filter((item) => {
    const matchesSearch =
      item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.answer.toLowerCase().includes(searchQuery.toLowerCase());

    if (activeTab === "all") return matchesSearch;
    return matchesSearch && item.category === activeTab;
  });

  const generalFAQs = filteredFAQs.filter(
    (item) => item.category === "general"
  );
  const featureFAQs = filteredFAQs.filter(
    (item) => item.category === "features"
  );
  const troubleshootingFAQs = filteredFAQs.filter(
    (item) => item.category === "troubleshooting"
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4, textAlign: "center" }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          className="font-bold"
        >
          FAQ
        </Typography>
      </Box>

      <Card className="mb-8 shadow-sm">
        <CardContent className="p-6 bg-slate-50 dark:bg-slate-800/50">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
            <input
              type="text"
              placeholder="Search for answers..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full rounded-md border border-input bg-background py-2 pl-10 pr-4 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring"
            />
          </div>
        </CardContent>
      </Card>

      <div className="mb-4">
        <Tabs defaultValue="all" value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="w-full overflow-x-auto no-scrollbar justify-start mb-6 bg-slate-100 dark:bg-slate-800/50">
            <TabsTrigger value="all">All Questions</TabsTrigger>
            <TabsTrigger value="general">General</TabsTrigger>
            <TabsTrigger value="features">Features</TabsTrigger>
            <TabsTrigger value="troubleshooting">Troubleshooting</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {generalFAQs.length > 0 && (
        <FAQSection
          title="General Questions"
          faqs={generalFAQs}
          prefix="general"
        />
      )}
      {featureFAQs.length > 0 && (
        <FAQSection
          title="Features & Usage"
          faqs={featureFAQs}
          prefix="features"
        />
      )}
      {troubleshootingFAQs.length > 0 && (
        <FAQSection
          title="Troubleshooting"
          faqs={troubleshootingFAQs}
          prefix="troubleshooting"
        />
      )}

      {filteredFAQs.length === 0 && (
        <Card className="text-center p-8 shadow-sm">
          <Typography variant="h6" component="h3" gutterBottom>
            No results found
          </Typography>
          <Typography variant="body2" color="text.secondary" className="mb-4">
            We couldn't find any FAQs matching your search. Try different
            keywords or browse all categories.
          </Typography>
          <Button
            variant="outline"
            onClick={() => {
              setSearchQuery("");
              setActiveTab("all");
            }}
          >
            Clear filters
          </Button>
        </Card>
      )}

      <Card className="mt-8 shadow-sm overflow-hidden">
        <div className="h-1 bg-primary/40"></div>
        <CardHeader className="bg-slate-50 dark:bg-slate-800/50">
          <Typography variant="h5" component="h2" className="font-bold">
            Still Have Questions?
          </Typography>
        </CardHeader>
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={8} mt={2}>
              <Typography paragraph>
                If you couldn't find the answer to your question, please contact
                our support team. We're here to help!
              </Typography>
              <Typography>
                Email us at:{" "}
                <a
                  href="mailto:admin@deocto.com"
                  className="text-primary hover:underline"
                >
                  admin@deocto.com
                </a>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default FAQPage;
