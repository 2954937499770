import { Card, CardContent } from "components/ui/card";
import { Loader2 } from "lucide-react";

export default function LoadingTab({ text }: { text: string }) {
  return (
    <Card>
      <CardContent className="flex items-center justify-center gap-2 pt-6">
        <Loader2 className="h-4 w-4 animate-spin" />
        <span>{text} ...</span>
      </CardContent>
    </Card>
  );
}
