import { useLocation, useNavigate } from "react-router";

import StockOverview from "components/StockOverview";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";

import { useAuth0 } from "@auth0/auth0-react";

import LockedDilutionChart from "./tabs-locked/LockedDilutionChart";
import LockedLevel2 from "./tabs-locked/LockedLevel2";
import DilutionChart from "./tabs/DilutionChart";
import Level2Table from "./tabs/Level2Table";
import StockNews from "./tabs/StockNews";
import StockSec from "./tabs/StockSec";
import InsiderTransactions from "./tabs/insider-transactions/InsiderTransactions";

interface StockTabsProps {
  stockName: string;
}

export default function StockTabs({ stockName }: StockTabsProps) {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentTab = () => {
    const hash = location.hash.replace("#", "");
    return hash || "overview";
  };

  const handleTabChange = (value: string) => {
    if (value === "overview") {
      navigate(location.pathname);
      return;
    }
    navigate(`${location.pathname}#${value}`);
  };

  return (
    <Tabs value={getCurrentTab()} onValueChange={handleTabChange}>
      <TabsList className="flex w-full overflow-x-auto pb-1 no-scrollbar justify-between sm:px-5">
        <TabsTrigger value="overview">Overview</TabsTrigger>
        <TabsTrigger value="level2">Level 2</TabsTrigger>
        <TabsTrigger value="dilution">Dilution</TabsTrigger>
        <TabsTrigger value="insider">Insider Transactions</TabsTrigger>
        <TabsTrigger value="news">News</TabsTrigger>
        <TabsTrigger value="sec">SEC</TabsTrigger>
      </TabsList>

      <TabsContent value="overview">
        <StockOverview stock={stockName} />
      </TabsContent>
      <TabsContent value="news">
        <StockNews stock={stockName} />
      </TabsContent>
      <TabsContent value="sec">
        <StockSec stock={stockName} />
      </TabsContent>

      <TabsContent value="level2">
        {!isAuthenticated ? (
          <LockedLevel2 />
        ) : (
          <Level2Table stock={stockName} />
        )}
      </TabsContent>
      <TabsContent value="dilution">
        {!isAuthenticated ? (
          <LockedDilutionChart />
        ) : (
          <DilutionChart stock={stockName} />
        )}
      </TabsContent>
      <TabsContent value="insider">
        <InsiderTransactions stock={stockName} />
      </TabsContent>
    </Tabs>
  );
}
