import { useAuth0 } from "@auth0/auth0-react";

export function useAuth() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const handleLogin = () => {
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      logout();
    }
  };

  return {
    isAuthenticated,
    handleLogin
  };
}