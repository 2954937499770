import { Clock } from "lucide-react";
import { Card, CardContent } from "./card";

interface RateLimitCardProps {
  limitCount?: number;
  endpoint?: string;
}

export default function RateLimitCard({
  limitCount,
  endpoint = "this feature",
}: RateLimitCardProps) {
  return (
    <Card className="mx-auto max-w-lg border-amber-300 dark:border-amber-700">
      <CardContent className="flex flex-col items-center justify-center gap-4 py-12">
        <div className="rounded-full bg-amber-100 p-3 dark:bg-amber-900/30">
          <Clock className="h-8 w-8 text-amber-600 dark:text-amber-400" />
        </div>
        <div className="text-center">
          <h3 className="text-lg font-semibold">Access Limit Reached</h3>

          <p className="mt-2 text-sm text-muted-foreground">
            You've reached your access limit for {endpoint}.
          </p>

          {limitCount && (
            <p className="mt-2 text-sm text-muted-foreground">
              Your account is limited to {limitCount} {endpoint} request
              {limitCount !== 1 ? "s" : ""} in this time period.
            </p>
          )}

          <p className="mt-4 text-amber-600 dark:text-amber-400">
            Please check back later to view more data.
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
