import axios from "axios";
import { useAuthenticatedRequest } from "hooks/useAuthenticatedRequest";
import { DilutionData } from "types/dilution";
import { InsiderTransaction } from "types/insiderTransactionsTypes";
import { Level2 } from "types/level2";

import { useQuery } from "@tanstack/react-query";

const API_STOCKS_URL = `${process.env.REACT_APP_API_BACKEND_URL}/stocks`;

export function useLevel2Data(stock: string, enabled: boolean = false) {
  const authenticatedRequest = useAuthenticatedRequest();

  return useQuery<Level2>({
    queryKey: ["level2", stock],
    queryFn: () => authenticatedRequest(`${API_STOCKS_URL}/${stock}/lvl2`),
    retry: (_, error) => {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        return false;
      }
      return false;
    },
    enabled: enabled,
  });
}

export function useDilutionData(stock: string) {
  const authenticatedRequest = useAuthenticatedRequest();

  return useQuery<DilutionData>({
    queryKey: ["dilutionData", stock],
    queryFn: () =>
      authenticatedRequest(`${API_STOCKS_URL}/${stock}/shares-structure`),
    retry: (_, error) => {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        return false;
      }
      return false; // No retries for any error
    },
  });
}

export function useInsiderTransaction(stock: string) {
  const authenticatedRequest = useAuthenticatedRequest();

  return useQuery<InsiderTransaction[]>({
    queryKey: ["insider", stock],
    queryFn: () => authenticatedRequest(`${API_STOCKS_URL}/${stock}/insider`),
    retry: (_, error) => {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        return false;
      }
      return false; // No retries for any error
    },
  });
}
