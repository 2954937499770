import { Card, CardContent } from "../components/ui/card";

export default function PrivacyPolicyPage() {
  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <Card className="prose dark:prose-invert">
        <CardContent className="space-y-4">

          <p><strong>Effective Date:</strong> March 31, 2025</p>

          <p>
            Deocto (“we”, “our”, or “us”) values your privacy. This Privacy Policy describes how we collect, use, and protect your personal information when you use any of our products or services, including ASE (All Seeing Eye), and when you interact with our websites, applications, or platforms (“Services”).
          </p>

          <h2>1. Information We Collect</h2>
          <p>We may collect the following types of information:</p>

          <h3>a. Personal Information</h3>
          <p>When you register, subscribe, or interact with our Services, we may collect:</p>
          <ul className="list-disc list-inside">
            <li>Name</li>
            <li>Email address</li>
            <li>Account credentials</li>
            <li>Payment details (handled securely by our payment provider)</li>
          </ul>

          <h3>b. Technical & Usage Data</h3>
          <p>We may collect:</p>
          <ul className="list-disc list-inside">
            <li>IP address</li>
            <li>Browser and device information</li>
            <li>Pages visited and actions taken within the platform</li>
            <li>Time and date of activity</li>
          </ul>

          <p>We use <strong>Amplitude</strong> to understand how users engage with our Services, which helps us improve functionality and user experience.</p>

          <h3>c. Authentication</h3>
          <p>We use <strong>Auth0</strong> to manage secure user authentication. Information you provide during login or registration is securely handled via Auth0’s infrastructure.</p>

          <h3>d. Payments</h3>
          <p>All payment processing is handled by <strong>PayPal</strong>. We do not store or process your credit card or banking information on our servers.</p>

          <h2>2. How We Use Your Information</h2>
          <ul className="list-disc list-inside">
            <li>To provide and operate the Services</li>
            <li>To manage subscriptions and accounts</li>
            <li>To communicate with you about your account or updates</li>
            <li>To analyze and improve our Services</li>
            <li>To ensure security and prevent fraud</li>
            <li>To comply with legal obligations</li>
          </ul>

          <h2>3. Sharing of Information</h2>
          <p>We do not sell or rent your personal information. We may share it only in the following cases:</p>
          <ul className="list-disc list-inside">
            <li>With trusted service providers (Auth0, PayPal, Amplitude)</li>
            <li>If required by law, regulation, or legal process</li>
            <li>To protect the rights, property, or safety of Deocto or others</li>
          </ul>

          <h2>4. Cookies & Tracking</h2>
          <p>We use cookies and similar technologies to:</p>
          <ul className="list-disc list-inside">
            <li>Keep you logged in</li>
            <li>Analyze usage patterns</li>
            <li>Improve functionality and performance</li>
          </ul>
          <p>You can control or delete cookies through your browser settings.</p>

          <h2>5. Data Retention</h2>
          <p>We retain your personal information only as long as necessary to provide our Services, comply with legal obligations, and resolve disputes.</p>

          <h2>6. Data Security</h2>
          <p>We use industry-standard measures to protect your data, including encryption, secure authentication via Auth0, and strict access controls.</p>

          <h2>7. Your Rights</h2>
          <p>Depending on your location, you may have rights under data protection laws, including:</p>
          <ul className="list-disc list-inside">
            <li>Accessing or updating your personal data</li>
            <li>Requesting deletion of your data</li>
            <li>Objecting to or restricting processing</li>
          </ul>
          <p>You can contact us at <a href="mailto:admin@deocto.com">admin@deocto.com</a> to make a request.</p>

          <h2>8. Changes to This Policy</h2>
          <p>We may update this Privacy Policy occasionally. Changes will be posted on this page with a new “Effective Date.” Continued use of the Services after changes means you accept the updated policy.</p>

          <h2>9. Contact Us</h2>
          <p>If you have any questions or privacy-related concerns, please contact us at:</p>
          <p><strong>Email:</strong> <a href="mailto:admin@deocto.com">admin@deocto.com</a></p>

        </CardContent>
      </Card>
    </div>
  );
}
