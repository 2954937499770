import { useState, FormEvent, useCallback } from "react";
import { Loader2 } from "lucide-react";
import { Command, CommandInput, CommandList, CommandEmpty } from "./command";
import useGetStocksOptions from "../../hooks/useGetStocksOptions";
import { StockTier, TierDisplay } from "../../types/stock";
import StocksCommandGroup from "./stocks-command-group";
import { useNavigate } from "react-router";

const TierColors: Record<StockTier, string> = {
  "OTCQX International":
    "bg-emerald-100 text-emerald-800 dark:bg-emerald-900 dark:text-emerald-300",
  OTCQX:
    "bg-emerald-100 text-emerald-800 dark:bg-emerald-900 dark:text-emerald-300",
  OTCQB: "bg-amber-100 text-amber-800 dark:bg-amber-900 dark:text-amber-300",
  "Pink Current Information":
    "bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300",
  "Pink Limited Information":
    "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300",
  "Expert Market":
    "bg-zinc-100 text-zinc-800 dark:bg-zinc-900 dark:text-zinc-300",
};

const Search = () => {
  const [inputValue, setInputValue] = useState("");
  const { stocks, isLoading, error } = useGetStocksOptions(inputValue);
  const navigate = useNavigate();

  const handleOptionSelect = useCallback(
    (stockSymbol: string) => {
      const trimmedValue = stockSymbol?.trim().toLowerCase();
      if (trimmedValue) {
        navigate(`/stocks/${trimmedValue}`);
        setInputValue("");
      }
    },
    [navigate]
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (Array.isArray(stocks) && stocks.length === 1) {
      handleOptionSelect(stocks[0].symbol);
    }
  };

  const getFormattedTier = (tier: string) =>
    TierDisplay[tier as StockTier] || tier;
  const getTierBadgeColor = (tier: string) =>
    TierColors[tier as StockTier] ||
    "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300";

  const formatPrice = (price: number) => {
    return price;
  };

  const getHighlightedText = useCallback(
    (text: string) => {
      const cleanInput = inputValue?.trim().toLowerCase() ?? "";
      const cleanText = text?.toLowerCase() ?? "";
      const matchStart = cleanText.indexOf(cleanInput);

      if (matchStart === -1) return { before: text, match: "", after: "" };

      return {
        before: text.slice(0, matchStart),
        match: text.slice(matchStart, matchStart + cleanInput.length),
        after: text.slice(matchStart + cleanInput.length),
      };
    },
    [inputValue]
  );

  const renderContent = () => {
    if (!inputValue?.trim()) {
      return null;
    }

    if (isLoading) {
      return (
        <div className="py-6 text-center">
          <Loader2 className="h-4 w-4 animate-spin mx-auto" />
        </div>
      );
    }

    if (error) {
      return (
        <CommandEmpty className="py-6 text-center text-sm text-red-500">
          {error}
        </CommandEmpty>
      );
    }

    return (
      <StocksCommandGroup
        stocks={stocks}
        inputValue={inputValue}
        onSelect={handleOptionSelect}
      />
    );
  };

  return (
    <div className="flex flex-col items-center justify-center px-4 mt-40 space-y-6">
      <div className="text-center space-y-2">
        <h1 className="text-3xl md:text-4xl font-bold">
          Search <span className="italic">Any</span> Stock
        </h1>
        <p className="text-sm md:text-base text-muted-foreground">
          Explore data, trends, and insights.
        </p>
      </div>

      <div className="relative w-full max-w-2xl">
        <form onSubmit={handleSubmit}>
          <Command
            className="rounded-lg border shadow-sm overflow-hidden bg-slate-300/50 dark:bg-slate-700/50 transition-colors duration-200 focus-within:bg-background"
            shouldFilter={false}
          >
            <CommandInput
              value={inputValue}
              onValueChange={setInputValue}
              placeholder="Search"
              className="h-11 text-base"
            />
            <CommandList>{renderContent()}</CommandList>
          </Command>
        </form>
      </div>
    </div>
  );
};

export default Search;
