import { Level2 } from "../types/level2";

export const mockedLevel2: Level2 = {
  bid: [
    {
      price: 0.0001,
      size: 343472849,
      marketMaker: "CDEL",
    },
    {
      price: 0.0001,
      size: 232008541,
      marketMaker: "GTSM",
    },
    {
      price: 0.0001,
      size: 151243317,
      marketMaker: "CSTI",
    },
    {
      price: 0.0001,
      size: 136066889,
      marketMaker: "ETRF",
    },
    {
      price: 0.0001,
      size: 44460000,
      marketMaker: "NITE",
    },
    {
      price: 0.0001,
      size: 38064004,
      marketMaker: "OTCN",
    },
    {
      price: 0.0001,
      size: 25440099,
      marketMaker: "INTL",
    },
    {
      price: 0.0001,
      size: 10000,
      marketMaker: "STXG",
    },
  ],
  ask: [
    {
      price: 0.0002,
      size: 900000,
      marketMaker: "OTCN",
    },
    {
      price: 0.0002,
      size: 10000,
      marketMaker: "GTSM",
    },
    {
      price: 0.0003,
      size: 11150000,
      marketMaker: "CDEL",
    },
    {
      price: 0.0003,
      size: 9249166,
      marketMaker: "CSTI",
    },
    {
      price: 0.0003,
      size: 4000000,
      marketMaker: "ETRF",
    },
    {
      price: 0.0003,
      size: 500000,
      marketMaker: "NITE",
    },
    {
      price: 0.0003,
      size: 379999,
      marketMaker: "INTL",
    },
    {
      price: 0.02,
      size: 10000,
      marketMaker: "STXG",
    },
  ],
};

export const mockedDilutionData = Array.from({ length: 12 }, (_, i) => ({
  date: new Date(2023, i, 1).toISOString(),
  authorizedShares: 1000000 + Math.random() * 500000,
  unrestrictedShares: 400000 + Math.random() * 200000,
  restrictedShares: 300000 + Math.random() * 150000,
  dtcShares: 200000 + Math.random() * 100000,
}));
