import { Card, CardContent } from "components/ui/card";
import { Users } from "lucide-react";

interface InsiderTransactionsEmptyStateProps {
  stock: string;
}

export default function InsiderTransactionsEmptyState({
  stock,
}: InsiderTransactionsEmptyStateProps) {
  return (
    <Card className="w-full">
      <CardContent className="flex flex-col items-center justify-center py-16">
        <Users className="h-12 w-12 text-muted-foreground/50" />
        <div className="text-center mt-4">
          <h3 className="text-lg font-semibold">
            No Insider Transactions Found
          </h3>
          <p className="mt-2 text-sm text-muted-foreground max-w-md mx-auto">
            There are no reported insider transactions for {stock.toUpperCase()}{" "}
            in our database. Check back later for updates.
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
