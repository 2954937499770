import React from "react";

import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Box } from "@mui/material";

import { Stock } from "../types/stock";
import { Badge } from "./ui/badge";

interface StockCardProps {
  stock: Stock;
}

const StockCard: React.FC<StockCardProps> = ({ stock }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-2xl font-bold">{stock.symbol}</CardTitle>
          <Badge>{stock.exchange}</Badge>
        </CardHeader>
        <CardContent>
          <div className="flex justify-between items-end">
            <div>
              <p className="text-sm text-muted-foreground">{stock.name}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default StockCard;
