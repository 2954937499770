import { LockIcon } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Button } from "./ui/button";
import { useAuth } from "../hooks/useAuth";

interface SignInCardProps {
  title?: string;
  description?: string;
}

export default function SignInCard({
  title = "Sign in to Continue",
  description = "Get instant access to detailed market data and trading insights",
}: SignInCardProps) {
  const { isAuthenticated, handleLogin } = useAuth();

  return (
    <Card className="w-full max-w-md mx-auto -mt-32 sm:mt-0">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center">
          {title}
        </CardTitle>
        <CardDescription className="text-center">{description}</CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col items-center">
        <LockIcon
          className="w-16 h-16 text-muted-foreground mb-4"
          aria-hidden="true"
        />
        <Button onClick={handleLogin} className="w-full max-w-xs">
          {!isAuthenticated ? "Sign In" : "Sign Out"}
        </Button>
      </CardContent>
    </Card>
  );
}
