import { useState } from "react";
import { MenuIcon, SearchIcon, User } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
} from "../ui/command";
import { Button } from "../ui/button";
import useGetStocksOptions from "../../hooks/useGetStocksOptions";
import { ThemeToggle } from "../ThemeToggle";
import StocksCommandGroup from "../ui/stocks-command-group";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router";

const AppBar = () => {
  const { isAuthenticated, handleLogin } = useAuth();
  const [searchOpen, setSearchOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { stocks } = useGetStocksOptions(inputValue);
  const navigate = useNavigate();

  const handleOptionSelect = (value: string) => {
    navigate(`/stocks/${value.trim()}`);
    setInputValue("");
    setSearchOpen(false);
  };

  return (
    <div className="border-b">
      <div className="flex h-16 items-center px-4">
        <div className="flex items-center space-x-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <MenuIcon className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-48">
              <DropdownMenuItem onClick={() => navigate("/")}>
                Home
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate("/pricing")}>
                Pricing
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate("/faq")}>
                FAQ
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate("/about")}>
                About
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="flex flex-1 items-center justify-end space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setSearchOpen(true)}
            className="px-2"
          >
            <SearchIcon className="h-5 w-5" />
          </Button>

          <CommandDialog open={searchOpen} onOpenChange={setSearchOpen}>
            <Command shouldFilter={false}>
              <CommandInput
                placeholder="Search"
                value={inputValue}
                onValueChange={setInputValue}
              />
              <CommandList>
                <StocksCommandGroup
                  stocks={stocks}
                  inputValue={inputValue}
                  onSelect={handleOptionSelect}
                />{" "}
              </CommandList>
            </Command>
          </CommandDialog>

          <ThemeToggle />

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="ml-2">
                <User className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-48">
              <DropdownMenuItem onClick={handleLogin}>
                {isAuthenticated ? "Sign out" : "Sign in"}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

export default AppBar;
