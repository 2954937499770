import { useEffect } from "react";
import { Typography, Container, Grid, Avatar } from "@mui/material";
import { Card, CardContent, CardHeader } from "../components/ui/card";
import { logEvent } from "../util/Amplitude";
import { AmplitudeEventsEnum } from "../types/amplitudeEvents";
import { motion } from "framer-motion";
import { LineChart, BarChart, TrendingUp, Shield } from "lucide-react";
import { ReactComponent as BearSVG } from "../assets/bear.svg";
import { ReactComponent as BullSVG } from "../assets/bull.svg";

interface TeamMember {
  name: string;
  role: string;
  bio: string;
  avatar: React.ReactNode;
}

const teamMembers: TeamMember[] = [
  {
    name: "Mr. Takson",
    role: "Co-Founder & CEO",
    bio: "A seasoned CEO and senior software engineer with over a decade of experience, leading product innovation and business strategy to deliver next-level trading tools for modern markets.",
    avatar: <BullSVG width={100} height={100} />,
  },
  {
    name: "Mr. Tapeta",
    role: "Co-Founder & CTO",
    bio: "A veteran software engineer with over a decade of experience, architecting the high-performance systems that power All Seeing Eye and constantly pushing the limits of what traders can achieve.",
    avatar: <BearSVG width={80} height={80} />,
  },
];

const AboutPage: React.FC = () => {
  useEffect(() => {
    logEvent(AmplitudeEventsEnum.PAGE_VIEW, { page: "About" });
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        className="mb-12"
      >
        <Card className="shadow-sm overflow-hidden">
          <div className="h-1 bg-primary/40"></div>
          <CardHeader className="bg-slate-50 dark:bg-slate-800/50 ">
            <Typography variant="h5" component="h2" className="font-bold ">
              Our Mission
            </Typography>
          </CardHeader>
          <CardContent className="pt-6">
            <Grid container spacing={6}>
              <Grid item xs={12} md={8}>
                <Typography paragraph className="text-lg font-medium mb-6">
                  All Seeing Eye was designed to empower traders at every level
                  — whether you're exploring the dynamic OTC markets or trading
                  NASDAQ and NYSE tickers with precision.
                </Typography>
                <Typography paragraph className="mb-6">
                  Our platform brings together real-time Level 2 market data,
                  insider trading activity, SEC filings, news tracking, and
                  share structure insights — all in one lightning-fast,
                  intuitive interface.
                </Typography>
                <Typography paragraph className="text-primary font-medium">
                  Our mission is clear: to give traders unmatched speed,
                  transparency, and intelligent market analysis — so you can
                  trade smarter, every day.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="grid grid-cols-2 gap-4 h-full">
                  <motion.div
                    whileHover={{ y: -5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                    className="bg-white dark:bg-slate-800 p-4 rounded-lg shadow-sm border border-slate-200 dark:border-slate-700 flex flex-col items-center justify-center text-center"
                  >
                    <LineChart className="h-8 w-8 mb-2 text-primary" />
                    <Typography className="text-sm font-medium">
                      Real-Time Data
                    </Typography>
                  </motion.div>
                  <motion.div
                    whileHover={{ y: -5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                    className="bg-white dark:bg-slate-800 p-4 rounded-lg shadow-sm border border-slate-200 dark:border-slate-700 flex flex-col items-center justify-center text-center"
                  >
                    <BarChart className="h-8 w-8 mb-2 text-primary" />
                    <Typography className="text-sm font-medium">
                      Market Analysis
                    </Typography>
                  </motion.div>
                  <motion.div
                    whileHover={{ y: -5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                    className="bg-white dark:bg-slate-800 p-4 rounded-lg shadow-sm border border-slate-200 dark:border-slate-700 flex flex-col items-center justify-center text-center"
                  >
                    <TrendingUp className="h-8 w-8 mb-2 text-primary" />
                    <Typography className="text-sm font-medium">
                      Trading Insights
                    </Typography>
                  </motion.div>
                  <motion.div
                    whileHover={{ y: -5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                    className="bg-white dark:bg-slate-800 p-4 rounded-lg shadow-sm border border-slate-200 dark:border-slate-700 flex flex-col items-center justify-center text-center"
                  >
                    <Shield className="h-8 w-8 mb-2 text-primary" />
                    <Typography className="text-sm font-medium">
                      Secure Platform
                    </Typography>
                  </motion.div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mb-8"
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{ mb: 6, textAlign: "center" }}
          className="font-bold"
        >
          Meet Our Team
        </Typography>

        <Grid container spacing={6}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} md={6} key={member.name}>
              <motion.div
                whileHover={{
                  boxShadow:
                    "0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Card className="h-full shadow-sm overflow-hidden transition-shadow duration-300">
                  <div className="h-1 bg-primary/40"></div>
                  <div className="p-6">
                    <div className="flex flex-col sm:flex-row gap-6 items-center sm:items-start">
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: "spring", stiffness: 300 }}
                        className="relative"
                      >
                        <div className="absolute inset-0 rounded-full bg-primary/10 blur-sm"></div>
                        <Avatar
                          alt={member.name}
                          sx={{
                            width: 100,
                            height: 100,
                            border: "3px solid white",
                            boxShadow: "0 4px 14px 0 rgba(0,0,0,0.1)",
                            position: "relative",
                            zIndex: 1,
                          }}
                        >
                          {member.avatar}
                        </Avatar>
                      </motion.div>
                      <div className="flex-1 text-center sm:text-left">
                        <Typography
                          variant="h5"
                          component="h3"
                          className="font-bold mb-1"
                        >
                          {member.name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className="text-primary font-medium mb-4"
                        >
                          {member.role}
                        </Typography>
                        <Typography>{member.bio}</Typography>
                      </div>
                    </div>
                  </div>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </Container>
  );
};

export default AboutPage;
