import { Badge } from "components/ui/badge";
import { Insider } from "types/insiderTransactionsTypes";

interface InsiderTransactionsInsiderInfoProps {
  insiders: Insider[];
}

export default function InsiderTransactionsInsiderInfo({
  insiders,
}: InsiderTransactionsInsiderInfoProps) {
  if (!insiders || insiders.length === 0) {
    return <div>No insider information</div>;
  }

  const getInsiderRoleBadges = (insider: Insider) => {
    const badges = [];

    if (insider.is_director) {
      badges.push(
        <Badge key="director" variant="outline" className="mr-1 pb-1 text-xs">
          Director
        </Badge>,
      );
    }

    if (insider.is_officer) {
      badges.push(
        <Badge key="officer" variant="outline" className="mr-1 pb-1 text-xs">
          Officer
        </Badge>,
      );
    }

    if (insider.is_ten_percent_owner) {
      badges.push(
        <Badge key="owner" variant="outline" className="mr-1 pb-1 text-xs">
          10% Owner
        </Badge>,
      );
    }

    return badges;
  };

  return (
    <div className="flex items-start gap-2 ml-5">
      <div>
        <div className=" font-semibold">
          {insiders.map((insider, i) => (
            <div key={i} className="truncate max-w-[160px] md:max-w-full">
              {insider.name}
              {i < insiders.length - 1 && ", "}
            </div>
          ))}
        </div>
        {insiders.length > 0 && (
          <div className="text-xs text-muted-foreground mt-0.5 truncate max-w-[160px] md:max-w-full">
            {insiders[0].title}
          </div>
        )}
        <div className="flex flex-wrap mt-1">
          {insiders.length > 0 && getInsiderRoleBadges(insiders[0])}
        </div>
      </div>
    </div>
  );
}
