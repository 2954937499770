import axios from "axios";

const API_URL = process.env.REACT_APP_API_BACKEND_URL;

export const initAuthSession = async (getToken: () => Promise<string>): Promise<void> => {
  try {
    const token = await getToken();
    await axios.post(`${API_URL}/auth/session`, {}, {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true
    });
  } catch (error) {
    console.error("Failed to initialize auth session:", error);
  }
};