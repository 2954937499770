import { useState } from "react";

import axios from "axios";
import AuthRequiredCard from "components/AuthRequiredCard";
import RateLimitCard from "components/ui/RateLimitCard";
import { Card } from "components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { AlertCircle, Calendar, Clock, Loader2 } from "lucide-react";
import { fetchStockSec } from "util/Http";

import { CardContent } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import LoadingTab from "./common/LoadingTab";

interface SecItem {
  symbol: string;
  published: string;
  link: string;
  title: string;
  form_type: string;
}

interface SecTableProps {
  stock: string;
}

export default function StockSec({ stock }: SecTableProps) {
  const [selectedNews, setSelectedNews] = useState<SecItem | null>(null);

  const getFormColor = (source: string): string => {
    return "bg-zinc-500/10 text-zinc-700 dark:text-zinc-400 ring-1 ring-zinc-500/20";
  };

  const { data, isLoading, isError, error } = useQuery<SecItem[]>({
    queryKey: ["StockSec", stock],
    queryFn: () => fetchStockSec(stock),
  });

  if (isLoading) {
    return <LoadingTab text="Loading SEC Filings" />;
  }

  if (isError) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 429) {
        const headers = error.response.headers;
        const rateLimit = parseInt(headers["x-ratelimit-limit"] || "0", 10);

        return <RateLimitCard limitCount={rateLimit} endpoint="SEC files" />;
      }

      if (error.response?.status === 403) {
        return (
          <AuthRequiredCard actionMessage="Please log in to view SEC files." />
        );
      }
    }

    return (
      <Card className="w-full">
        <CardContent className="flex flex-col justify-center items-center h-80 space-y-4">
          <div className="text-muted-foreground/50">
            <AlertCircle className="h-12 w-12" />
          </div>
          <div className="text-center space-y-2">
            <h3 className="font-medium text-lg">Unable to Load Stock SEC</h3>
            <p className="text-muted-foreground text-sm max-w-[250px]">
              We encountered an issue while fetching the SEC data. Please try
              again later.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card className="w-full">
        <CardContent className="flex flex-col items-center justify-center py-16">
          <AlertCircle className="h-12 w-12 text-muted-foreground/50" />
          <div className="text-center mt-4">
            <h3 className="text-lg font-semibold">No SEC Data Available</h3>
            <p className="mt-2 text-sm text-muted-foreground">
              Currently there is no SEC data for {stock.toUpperCase()}. This
              could be due to low trading volume or market conditions.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <div className="space-y-4">
      {data &&
        data.map((item: SecItem, index: number) => (
          <Card
            key={index}
            className="p-4 hover:bg-accent hover:text-accent-foreground transition-colors cursor-pointer"
            onClick={() => setSelectedNews(item)}
          >
            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-start space-x-2 text-sm">
                <div className="flex items-center text-muted-foreground">
                  <Calendar className="w-4 h-4" />
                  <span className="ml-1">{formatDate(item.published)}</span>
                </div>
                <div className="flex items-center text-muted-foreground">
                  <Clock className="w-4 h-4" />
                  <span className="ml-1">{formatTime(item.published)}</span>
                </div>
                <span
                  className={`px-2 py-0.5 rounded-full text-xs ${getFormColor("SEC")}`}
                >
                  SEC
                </span>
              </div>
              <h3 className="font-medium line-clamp-2">{item.title}</h3>
            </div>
          </Card>
        ))}

      <Dialog open={!!selectedNews} onOpenChange={() => setSelectedNews(null)}>
        <DialogContent className="sm:max-w-2xl">
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold">
              {selectedNews?.title}
            </DialogTitle>
          </DialogHeader>
          <div className="mt-4 space-y-4">
            <div className="flex items-center space-x-2 text-sm">
              <div className="flex items-center text-muted-foreground">
                <Calendar className="w-4 h-4" />
                <span className="ml-1">
                  {selectedNews && formatDate(selectedNews.published)}
                </span>
              </div>
              <div className="flex items-center text-muted-foreground">
                <Clock className="w-4 h-4" />
                <span className="ml-1">
                  {selectedNews && formatTime(selectedNews.published)}
                </span>
              </div>
              <span
                className={`px-2 py-0.5 rounded-full text-xs ${selectedNews ? getFormColor("SEC") : ""}`}
              >
                SEC
              </span>
            </div>
            <div className="pt-4 border-t">
              <a
                href={selectedNews?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 hover:bg-accent hover:text-accent-foreground rounded-md transition-colors"
              >
                Read Full Article
              </a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
