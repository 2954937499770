import { User } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import eye from "../../assets/eye.png";
import { ThemeToggle } from "../ThemeToggle";
import { useAuth } from "../../hooks/useAuth";

function HomePageBar() {
  const { isAuthenticated, handleLogin } = useAuth();

  return (
    <div className="fixed top-0 left-0 right-0 border-b bg-background z-50">
      <div className="flex h-16 items-center px-4">
        <div className="flex items-center space-x-3">
          <a href="/" className="flex items-center">
            <img src={eye} alt="eye" className="h-8 w-8" />
          </a>
          <a
            href="/"
            className="font-bold text-lg text-foreground hover:text-foreground/80 md:block"
          >
            All Seeing Eye
          </a>
        </div>

        <div className="flex flex-1 items-center justify-end space-x-2">
          <ThemeToggle />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <User className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleLogin}>
                {isAuthenticated ? "Sign out" : "Sign in"}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}

export default HomePageBar;
