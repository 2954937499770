import React from "react";
import { CommandGroup, CommandItem, CommandEmpty } from "./command";
import { Exchange, Stock } from "../../types/stock";

const ExchangeColors: Record<Exchange, string> = {
  NYSE: "bg-emerald-500/10 text-emerald-700 dark:text-emerald-400 ring-1 ring-emerald-500/20",
  OTC: "bg-amber-500/10 text-amber-700 dark:text-amber-400 ring-1 ring-amber-500/20",
  NASDAQ:
    "bg-pink-500/10 text-pink-700 dark:text-pink-400 ring-1 ring-pink-500/20",
  UNKNOWN:
    "bg-zinc-500/10 text-zinc-700 dark:text-zinc-400 ring-1 ring-zinc-500/20",
};

interface CommandGroupComponentProps {
  stocks: Stock[];
  inputValue: string;
  onSelect: (stockSymbol: string) => void;
}

const StocksCommandGroup: React.FC<CommandGroupComponentProps> = ({
  stocks,
  inputValue,
  onSelect,
}) => {
  const getExchangeBadgeColor = (tier: string) =>
    ExchangeColors[tier as Exchange] ||
    "bg-gray-500/10 text-gray-700 dark:text-gray-400 ring-1 ring-gray-500/20";

  const getHighlightedText = (text: string) => {
    const cleanInput = inputValue?.trim().toLowerCase() ?? "";
    const cleanText = text?.toLowerCase() ?? "";
    const matchStart = cleanText.indexOf(cleanInput);

    if (matchStart === -1) return { before: text, match: "", after: "" };

    return {
      before: text.slice(0, matchStart),
      match: text.slice(matchStart, matchStart + cleanInput.length),
      after: text.slice(matchStart + cleanInput.length),
    };
  };

  if (!stocks || stocks.length === 0) {
    return (
      <CommandEmpty className="py-6 text-center text-sm">
        No matching stocks found.
      </CommandEmpty>
    );
  }

  return (
    <CommandGroup>
      {stocks.map((stock) => {
        if (!stock) return null;
        const { before, match, after } = getHighlightedText(stock.symbol);

        return (
          <CommandItem
            key={stock.symbol}
            value={stock.symbol}
            onSelect={() => onSelect(stock.symbol)}
            className="px-4 py-3 cursor-pointer hover:bg-accent group border-b last:border-b-0"
          >
            <div className="flex items-start justify-between w-full gap-6">
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-6">
                  <span className="text-base tracking-tight">
                    {before}
                    {match && (
                      <span className="font-bold uppercase">{match}</span>
                    )}
                    {after}
                  </span>
                  <span className="text-sm text-muted-foreground truncate mt-0.5 font-medium">
                    {stock.name}
                  </span>
                </div>
              </div>
              <div className="text-right">
                <div
                  className={`h-5 px-2 rounded-full text-xs/0 font-medium tracking-wide ${getExchangeBadgeColor(stock.exchange)}`}
                >
                  {stock.exchange}
                </div>
              </div>
            </div>
          </CommandItem>
        );
      })}
    </CommandGroup>
  );
};

export default StocksCommandGroup;
