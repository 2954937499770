import { Badge } from "components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Calendar, ExternalLink } from "lucide-react";
import { InsiderTransaction } from "types/insiderTransactionsTypes";

import TransactionBadge from "./InsiderTransactionsTransactionBadge";
import { formatUtils } from "./InsiderTransactionsUtils";

interface InsiderTransactionsTransactionFullDetailsProps {
  transaction: InsiderTransaction;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function InsiderTransactionsTransactionFullDetails({
  transaction,
  open,
  onOpenChange,
}: InsiderTransactionsTransactionFullDetailsProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Transaction Details</DialogTitle>
        </DialogHeader>

        <div className="space-y-4 py-2">
          {/* Insider Information */}
          <div className="space-y-2">
            <h3 className=" font-medium text-lg">Insider</h3>
            <div className="bg-muted/40 p-3 rounded-md">
              {transaction.insiders.map((insider, i) => (
                <div key={i} className="mb-2 last:mb-0">
                  <div className="font-medium">{insider.name}</div>
                  <div className="text-sm text-muted-foreground">
                    {insider.title}
                  </div>
                  <div className="flex flex-wrap gap-1 mt-1">
                    {insider.is_director && (
                      <Badge variant="outline" className="text-xs">
                        Director
                      </Badge>
                    )}
                    {insider.is_officer && (
                      <Badge variant="outline" className="text-xs">
                        Officer
                      </Badge>
                    )}
                    {insider.is_ten_percent_owner && (
                      <Badge variant="outline" className="text-xs">
                        10% Owner
                      </Badge>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Filing Information */}
          <div className="space-y-2">
            <h3 className=" font-medium text-lg">Filing Information</h3>
            <div className="bg-muted/40 p-3 rounded-md">
              <div className="grid grid-cols-2 gap-2 text-sm">
                <span className="text-muted-foreground">Form Type:</span>
                <span className="font-medium">{transaction.form_type}</span>

                <span className="text-muted-foreground">Filing Date:</span>
                <span className="font-medium flex items-center gap-1.5">
                  <Calendar className="h-3.5 w-3.5 text-muted-foreground" />
                  {formatUtils.formatDate(transaction.period_of_report)}
                </span>

                <span className="text-muted-foreground">SEC Link:</span>
                <a
                  href={transaction.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary flex items-center gap-1 hover:underline"
                >
                  View Filing
                  <ExternalLink className="h-3 w-3" />
                </a>
              </div>
            </div>
          </div>

          {/* Transactions */}
          <div className="space-y-2">
            <h3 className="font-medium text-lg">Transactions</h3>
            <div className="space-y-3">
              {transaction.transactions.map((t, i) => (
                <div key={i} className="bg-muted/40 p-3 rounded-md">
                  <div className="flex items-center gap-2 mb-2">
                    <TransactionBadge transaction={t} />
                  </div>

                  <div className="grid grid-cols-2 gap-x-3 gap-y-2 text-sm">
                    <span className="text-muted-foreground">Security:</span>
                    <span className="font-medium">{t.security_type}</span>

                    <span className="text-muted-foreground">Shares:</span>
                    <span className="font-medium">
                      {formatUtils.formatNumber(t.shares)}
                      {t.ownership_change_percent && (
                        <span className="text-xs ml-1.5">
                          ({t.ownership_change_percent > 0 ? "+" : ""}
                          {t.ownership_change_percent.toFixed(1)}%)
                        </span>
                      )}
                    </span>

                    <span className="text-muted-foreground">Price:</span>
                    <span className="font-medium">
                      {t.price_per_share
                        ? formatUtils.formatCurrency(t.price_per_share)
                        : "N/A"}
                    </span>

                    {t.total_value !== undefined && (
                      <>
                        <span className="text-muted-foreground">Value:</span>
                        <span className="font-medium">
                          {formatUtils.formatCurrency(t.total_value)}
                        </span>
                      </>
                    )}

                    {t.shares_owned_after !== undefined && (
                      <>
                        <span className="text-muted-foreground">
                          Shares After:
                        </span>
                        <span className="font-medium">
                          {formatUtils.formatNumber(t.shares_owned_after)}
                        </span>
                      </>
                    )}
                  </div>

                  {t.is_10b5_1 && (
                    <div className="mt-2 pt-2 border-t border-border">
                      <div className="flex items-center gap-1.5">
                        <Badge
                          variant="outline"
                          className="text-[10px] px-1 py-0 h-4"
                        >
                          10b5-1
                        </Badge>
                        <span className="text-xs text-muted-foreground">
                          Planned transaction under SEC Rule 10b5-1
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Total Value */}
          <div className="pt-2 border-t border-border">
            <div className="flex justify-between items-center">
              <span className="font-medium">Total Transaction Value:</span>
              <span className="text-lg font-bold">
                {formatUtils.formatCurrency(
                  formatUtils.getTotalValue(transaction),
                )}
              </span>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
