import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Clock, AlertCircle } from "lucide-react";

const Level2MarketHoursCard = () => {
  const now = new Date();
  const etTime = new Date(
    now.toLocaleString("en-US", { timeZone: "America/New_York" })
  );
  const currentHour = etTime.getHours();
  const currentMinutes = etTime.getMinutes();
  const isWeekend = etTime.getDay() === 0 || etTime.getDay() === 6;
  const currentTime = currentHour * 100 + currentMinutes;
  const marketClose = 1600; // 4:00 PM ET

  const nextOpeningText = isWeekend
    ? "Opens Monday at 9:30 AM ET"
    : currentTime >= marketClose
      ? "Opens tomorrow at 9:30 AM ET"
      : "Opens today at 9:30 AM ET";

  return (
    <Card>
      <CardHeader className="pb-3">
        <div className="flex items-center space-x-2">
          <Clock className="h-5 w-5 text-red-500" />
          <CardTitle className="text-lg font-semibold">
            OTC Market Closed
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="rounded-lg bg-red-50 p-4">
          <div className="flex items-start">
            <AlertCircle className="mt-0.5 h-5 w-5 text-red-500" />
            <div className="ml-3">
              <p className="text-sm text-red-700">
                Level 2 is currently offline
              </p>
              <p className="mt-1 text-sm text-gray-600">
                Real-time level 2 data available Monday through Friday, 9:30 AM
                – 4:00 PM ET
              </p>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <h4 className="text-sm font-medium">Next Online Window</h4>
          <div className="text-sm text-gray-500">{nextOpeningText}</div>
        </div>

        <div>
          <p className="text-xs text-gray-500">
            Pre-market trading: 6:00 AM – 9:30 AM ET
            <br />
            After-hours trading: 4:00 PM – 5:00 PM ET
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default Level2MarketHoursCard;
