import {
  Award,
  Bookmark,
  Briefcase,
  DollarSign,
  FileText,
  Footprints,
  Gift,
  Package,
  RefreshCw,
  Scale,
  Timer,
  TrendingDown,
  TrendingUp,
  UserPlus,
  Wallet,
} from "lucide-react";
import {
  TransactionCode,
  TransactionCodeInfo,
} from "types/insiderTransactionsTypes";

export const TRANSACTION_CODE_MAP: Record<
  TransactionCode,
  TransactionCodeInfo
> = {
  P: {
    label: "Purchase",
    color: "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300",
    icon: <TrendingUp className="h-3.5 w-3.5" />,
    description: "Open market or private purchase of securities",
  },
  S: {
    label: "Sale",
    color: "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300",
    icon: <TrendingDown className="h-3.5 w-3.5" />,
    description: "Open market or private sale of securities",
  },
  A: {
    label: "Grant",
    color: "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300",
    icon: <Award className="h-3.5 w-3.5" />,
    description: "Grant, award, or other acquisition",
  },
  D: {
    label: "Disposition",
    color:
      "bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-300",
    icon: <Briefcase className="h-3.5 w-3.5" />,
    description: "Sale (or disposition) back to the issuer of the securities",
  },
  M: {
    label: "Exercise",
    color:
      "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300",
    icon: <Footprints className="h-3.5 w-3.5" />,
    description: "Exercise or conversion of derivative security",
  },
  X: {
    label: "Other",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <FileText className="h-3.5 w-3.5" />,
    description:
      "Exercise of in-the-money or at-the-money derivatives securities (usually options)",
  },
  V: {
    label: "Voluntary Report",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <FileText className="h-3.5 w-3.5" />,
    description: "Transaction voluntarily reported earlier than required",
  },
  F: {
    label: "Tax Payment",
    color:
      "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300",
    icon: <DollarSign className="h-3.5 w-3.5" />,
    description:
      "Payment of exercise price or tax liability by delivering or withholding securities",
  },
  I: {
    label: "Discretionary",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <Scale className="h-3.5 w-3.5" />,
    description:
      "Discretionary transaction, which is an order to the broker to execute the transaction at the best possible price",
  },
  C: {
    label: "Conversion",
    color:
      "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300",
    icon: <RefreshCw className="h-3.5 w-3.5" />,
    description: "Conversion of derivative security (usually options)",
  },
  E: {
    label: "Expiration Short",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <Timer className="h-3.5 w-3.5" />,
    description: "Expiration of short derivative position (usually options)",
  },
  H: {
    label: "Expiration Long",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <Timer className="h-3.5 w-3.5" />,
    description:
      "Expiration (or cancellation) of long derivative position with value received (usually options)",
  },
  O: {
    label: "Exercise Out of Money",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <Footprints className="h-3.5 w-3.5" />,
    description:
      "Exercise of out-of-the-money derivative securities (usually options)",
  },
  G: {
    label: "Gift",
    color: "bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300",
    icon: <Gift className="h-3.5 w-3.5" />,
    description: "Bona fide gift form of any clauses",
  },
  L: {
    label: "Small Acquisition",
    color: "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300",
    icon: <Package className="h-3.5 w-3.5" />,
    description: "Small acquisition",
  },
  W: {
    label: "Inheritance",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <Bookmark className="h-3.5 w-3.5" />,
    description:
      "Acquisition or disposition by will or laws of descent and distribution",
  },
  Z: {
    label: "Voting Trust",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <UserPlus className="h-3.5 w-3.5" />,
    description: "Deposit into or withdrawal from voting trust",
  },
  J: {
    label: "Other Acquisition",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <Package className="h-3.5 w-3.5" />,
    description:
      "Other acquisition or disposition (transaction described in footnotes)",
  },
  K: {
    label: "Equity Swap",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <RefreshCw className="h-3.5 w-3.5" />,
    description: "Transaction in equity swap or similar instrument",
  },
  U: {
    label: "Tender Offer",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
    icon: <Wallet className="h-3.5 w-3.5" />,
    description:
      "Disposition due to a tender of shares in a change of control transaction",
  },
};
