import { Lock } from "lucide-react";
import { Card, CardContent } from "./ui/card";

interface AuthRequiredCardProps {
  title?: string;
  message?: string;
  actionMessage?: string;
}

export default function AuthRequiredCard({
  title = "Authentication Required",
  message = "This feature requires you to be logged in.",
  actionMessage = "Please log in to access this feature.",
}: AuthRequiredCardProps) {
  return (
    <Card className="w-full backdrop-blur-sm">
      <CardContent className="flex flex-col items-center text-center py-12">
        <Lock className="w-12 h-12 mb-6" />
        <h2 className="text-xl font-medium mb-3">{title}</h2>
        <p className="mb-3 text-base">{message}</p>
        <p className="text-sm">{actionMessage}</p>
      </CardContent>
    </Card>
  );
}
