import { useMemo, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";

import HomePage from "./pages/HomePage";
import StockPage from "./pages/StockPage";
import NotFoundPage from "./pages/NotFoundPage";
import AboutPage from "./pages/AboutPage";
import FAQPage from "./pages/FAQPage";
import Layout from "./components/layout/Layout";
import GlobalLayout from "./components/layout/GlobalLayout";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { initAmplitude } from "./util/Amplitude";
import { initAuthSession } from "./util/AuthSession";
import PricingPage from "./pages/PricingPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router";

function App() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const queryClient = useMemo(() => new QueryClient(), []);

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route
            path="/"
            element={<GlobalLayout />}
            errorElement={<NotFoundPage />}
          >
            <Route index element={<HomePage />} />
            <Route element={<Layout />}>
              <Route path="stocks/:stock" element={<StockPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="faq" element={<FAQPage />} />
              <Route path="pricing" element={<PricingPage />} />
              <Route path="privacy" element={<PrivacyPolicyPage />} />
            </Route>
          </Route>
        )
      ),
    []
  );

  useEffect(() => {
    initAmplitude();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      initAuthSession(getAccessTokenSilently);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
