import { useEffect, useState, useCallback } from "react";
import { Stock } from "../types/stock";
import { fetchStockBySearchTerm } from "util/Http";

const MIN_SEARCH_LENGTH = 2;
const DEBOUNCE_DELAY = 300;

export default function useGetStocksOptions(searchTerm: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [stocks, setStocks] = useState<Stock[]>([]);

  const validateSearchTerm = useCallback((term: string): boolean => {
    const cleanTerm = term.trim();
    if (cleanTerm.length < MIN_SEARCH_LENGTH) return false;
    return /^[a-zA-Z0-9\s]*$/.test(cleanTerm);
  }, []);

  useEffect(() => {
    setError(null);

    if (!validateSearchTerm(searchTerm)) {
      setStocks([]);
      return;
    }

    const fetchSymbols = async () => {
      setIsLoading(true);

      try {
        const data = await fetchStockBySearchTerm(searchTerm);

        if (!Array.isArray(data)) {
          throw new Error("Invalid response format");
        }

        setStocks(data);
      } catch (err) {
        setError("Failed to fetch stock symbols");
        setStocks([]);
      } finally {
        setIsLoading(false);
      }
    };

    const timeoutId = setTimeout(fetchSymbols, DEBOUNCE_DELAY);
    return () => clearTimeout(timeoutId);
  }, [searchTerm, validateSearchTerm]);

  return { stocks: stocks || [], isLoading, error }; // Ensure we never return undefined
}
